<template>
  <JsonSearch :max-results="1500" :shouldSort="true" />

</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import { JsonSearch, ResultList, ResultListItem, ResultTitle, SearchInput, SearchResults } from 'vue-json-search'
import { JsonSearch } from 'vue-json-search'


export default {
  name: 'App',
  components: {
    // JsonSearch, ResultList, ResultListItem, ResultTitle, SearchInput, SearchResults
    JsonSearch,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
